// components/Sidebar.tsx
import React, { ReactNode, useEffect } from 'react';
import styles from './style.module.scss';
import WsStaticImage from '../WsStaticImage';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}
const WsSidebar: React.FC<SidebarProps> = ({ isOpen, onClose, children }) => {
  const sidebarClass = `${styles.sidebar} ${isOpen ? styles.open : styles.close}`;
  useEffect(() => {
    if (isOpen) {
      document.body.className += ' overflow-add';
    } else {
      document.body.className = document.body.className.replace(
        'overflow-add',
        ''
      );
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`${styles.overlay} ${isOpen ? styles.open : ''}`}
        onClick={onClose}
      />
      <div className={sidebarClass}>
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <WsStaticImage
            src={'/images/category/modal-close-img.svg'}
            alt="modal-close-img"
            width={16}
            height={16}
          />
        </button>
        <div className={`${styles['sidebar-details']} ${styles['pad-none']}`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default WsSidebar;
